<template>
  <IAmOverlay
    :loading="loadingPickTicket"
    :spinner-variant="'success'"
  >
    <b-card
      class="cursor-pointer scroll-container d-flex flex-row overflow-auto gap-2 px-75"
      no-body
    >
      <template v-for="train of trains">
        <div
          :key="`train-${train.id}`"
          :class="`my-50 py-25 px-50 rounded-lg rounded-lg shadow text-nowrap d-flex flex-column justify-content-between
          ${isSelectedTrain(train.id) ? 'border-success-2' : 'border-light-2'}
          `"
          @click="selectTrainHandle(train)"
        >
          <div class="text-center fw-800 text-info">
            Tàu {{ train.trainCode }}
          </div>

          <div :style="`${ isMobileView ? 'height: 70px' : 'height: 80px'}`">
            <b-img
              :src="isSelectedTrain(train.id) ? require(`@train/assets/train_font_active.svg`) : require(`@train/assets/train_font_default.svg`)"
              alt="Train"
              fluid
              center
              class="full-height-img"
            />
          </div>

          <div class="text-center px-2 my-25">
            <span
              class="promotion_class text-info text-underline font-italic cursor-pointer"
              @click.stop="handleShowTrainPromotion(train.id)"
            >
              {{ $t('train.search.result.viewPromotions') }}
            </span>

            <ModalDetailPromotionTrain
              :itineraryIndex="itineraryIndex"
              :trainId="train.id"
              :trainCode="train.trainCode"
            />
          </div>

          <div class="d-flex-center my-25">
            <span class="fw-800">
              {{ train.dateTimeDeparture }}
            </span>
          </div>

          <div class="d-flex-center mb-25">
            <span class="fw-800">
              {{ train.dateTimeArrival }}
            </span>
          </div>
        </div>
      </template>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  BCard, BImg,
} from 'bootstrap-vue'

import store from '@/store'
import IAmOverlay from '@/components/IAmOverlay.vue'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BImg,
    IAmOverlay,

    ModalDetailPromotionTrain: () => import('./ModalDetailPromotionTrain.vue'),
  },
  props: {
    itineraryIndex: {
      type: Number,
      required: true,
    },
    trains: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const {
      loadingPickTicket,
      stateTrainSelectedArray,
      stateTicketSelectedArray,

      delay,
    } = useTrainHandle()

    const isSelectedTrain = id => stateTrainSelectedArray.value.some(train => train.id === id)

    function selectedSeatNumbers(trainCode) {
      const ticketSelected = stateTicketSelectedArray.value[props.itineraryIndex]
      return ticketSelected.filter(tk => tk.trainCode === trainCode).length
    }

    function selectTrainHandle(train) {
      store.commit('app-train/SET_SELECTED_TRAIN', train)
    }

    async function handleShowTrainPromotion(trainId) {
      await delay(200)
      this.$bvModal.show(`modal-detail-promotion-train-${props.itineraryIndex}-${trainId}`)
    }

    return {
      loadingPickTicket,
      stateTrainSelectedArray,
      selectTrainHandle,
      isSelectedTrain,
      selectedSeatNumbers,

      handleShowTrainPromotion,
    }
  },
}
</script>

<style lang="scss">
/* Custom scrollbar cho Chrome, Safari */
.scroll-container::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom scrollbar cho Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.full-height-img {
  height: 100%; /* Đặt chiều cao của b-img bằng 100% của thẻ div */
  width: auto;  /* Đảm bảo tỷ lệ không bị thay đổi */
}

.promotion_class {
  opacity: .75;

  &:hover {
    opacity: 1;
  }
}

</style>
