var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingPickTicket,
      "spinner-variant": 'success'
    }
  }, [_c('b-card', {
    staticClass: "cursor-pointer scroll-container d-flex flex-row overflow-auto gap-2 px-75",
    attrs: {
      "no-body": ""
    }
  }, [_vm._l(_vm.trains, function (train) {
    return [_c('div', {
      key: "train-".concat(train.id),
      class: "my-50 py-25 px-50 rounded-lg rounded-lg shadow text-nowrap d-flex flex-column justify-content-between\n        ".concat(_vm.isSelectedTrain(train.id) ? 'border-success-2' : 'border-light-2', "\n        "),
      on: {
        "click": function click($event) {
          return _vm.selectTrainHandle(train);
        }
      }
    }, [_c('div', {
      staticClass: "text-center fw-800 text-info"
    }, [_vm._v(" Tàu " + _vm._s(train.trainCode) + " ")]), _c('div', {
      style: "".concat(_vm.isMobileView ? 'height: 70px' : 'height: 80px')
    }, [_c('b-img', {
      staticClass: "full-height-img",
      attrs: {
        "src": _vm.isSelectedTrain(train.id) ? require("@train/assets/train_font_active.svg") : require("@train/assets/train_font_default.svg"),
        "alt": "Train",
        "fluid": "",
        "center": ""
      }
    })], 1), _c('div', {
      staticClass: "text-center px-2 my-25"
    }, [_c('span', {
      staticClass: "promotion_class text-info text-underline font-italic cursor-pointer",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.handleShowTrainPromotion(train.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.viewPromotions')) + " ")]), _c('ModalDetailPromotionTrain', {
      attrs: {
        "itineraryIndex": _vm.itineraryIndex,
        "trainId": train.id,
        "trainCode": train.trainCode
      }
    })], 1), _c('div', {
      staticClass: "d-flex-center my-25"
    }, [_c('span', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(train.dateTimeDeparture) + " ")])]), _c('div', {
      staticClass: "d-flex-center mb-25"
    }, [_c('span', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(train.dateTimeArrival) + " ")])])])];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }